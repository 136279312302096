<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-img class="dark align-center" :src="TheCatchingGuy"></v-img>

          <p>
            Todd Coburn, A.K.A &ndash; &ldquo;The Catching Guy&rdquo; started
            conducting camps and clinics for a living in 2000, held his first
            &ldquo;catcher only&rdquo; event in Northern Nevada back in 2006 and
            now, he events around the country throughout the year.
          </p>

          <p>
            Coach Todd is one of the most sought after catching coaches in the
            world who is known for not only his passion for the position, but
            also his passion for teaching the position. He is a lifetime catcher
            who loves spreading the knowledge to anyone who wants to listen and
            learn.
          </p>

          <h1>Camp Registration</h1>

          <p>
            All camps for 2022 @BAS have past. For infomation on future "The
            Catching Guy" camps click the button below.
          </p>
          <v-btn
            href="https://thecatchingguy.com/the-catching-guy-events"
            target="_blank"
            color="green"
            small
          >
            "The Catching Guy"
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-img class="dark align-center" :src="ToddCoburnProfilePic"></v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h1>Todd's Mission</h1>
          <p>
            Our mission is to provide youth athletes the opportunity to learn
            the fundamentals of the position of catcher in a fun and safe
            environment. Our goal is to improve their levels of understanding
            and skill which might, in turn, improve their performance and
            increase their success. It is hoped that through these successes our
            athletes will gain more confidence in themselves and develop a
            greater self-worth, on the field, at home, in the classroom, and in
            life.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="text-center">
            Check out Todd's Characteristics of a Successful Catcher
          </h3>

          <v-tabs v-model="tab" background-color="transparent" grow show-arrows>
            <v-tab v-for="skill in catcher.skills" :key="skill">
              {{ skill }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(video, index) in catcher.videos" :key="index">
              <v-card flat>
                <v-card-text>
                  <div class="iframe-container">
                    <iframe
                      :src="video"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TheCatchingGuy from '@/assets/images/TheCatchingGuy.png'
import ToddCoburnProfilePic from '@/assets/images/ToddCoburnProfilePic.jpeg'
export default {
  data() {
    return {
      TheCatchingGuy: TheCatchingGuy,
      ToddCoburnProfilePic: ToddCoburnProfilePic,
      catcher: {
        skills: [
          'Leadership',
          'Mental Toughness',
          'Physical Toughness',
          'Skills',
        ],
        videos: [
          'https://www.youtube.com/embed/EMSa0aI0aTo',
          'https://www.youtube.com/embed/YJRVQlvaHzY',
          'https://www.youtube.com/embed/FZ60KR1kYxA',
          'https://www.youtube.com/embed/CmR9dwSGRwA',
        ],
      },
      tab: null,
    }
  },
}
</script>

<style>
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
